import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import './introPageStyling.css'
import Header from '../../components/header/header';
const IntroPage = () =>{

    return <div>
    <Header introPage={true}></Header>
    <div className="headlineText">
        <TheSubtext></TheSubtext>
        <h2 className='appDescription'>Build and save workouts with FitMaker.</h2>
        <h2 className='appDescription'>Exercising has never been easier</h2>
    </div>
    <div className="flex" >
        <div className='flexColumn gridItem' /* style={{justifyContent:"right"}} */>
            <Subtext2 text="Your workout, your rules"></Subtext2>
            <h2 className='appDescription'
            >Take control over your workouts
            and decide what's best for you</h2>
        </div>
        <Image path={require('./homepageFotos/manExercising.jpg')}></Image>
        
        <Image path={require('./homepageFotos/usingPhone.jpg')}></Image>
        <div className='flexColumn gridItem secondTextBelow' /* style={{justifyContent:"right"}} */>
            <Subtext2 text="Create a workout in seconds"></Subtext2>
            <h2 className='appDescription'
            >Build your own workouts with a user-friendly interface</h2>
        </div>
        
        
        <div className='flexColumn gridItem' /* style={{justifyContent:"right"}} */>
            <Subtext2 text="Stay motivated"></Subtext2>
            <h2 className='appDescription'
            >FitMaker makes it easy to build a habit of exercising</h2>
        </div>
        <Image path={require('./homepageFotos/womanJogging.jpg')}></Image>
    </div>
    
    
</div>
    
}
const TheSubtext = () =>{
    return <div  className='subtextContainer'>
        <h1 className='subtext top'>
            <span>All </span>
             your workouts
        </h1>
        <h1  className='subtext bottom'>
            in <span>one </span>
            place
        </h1>
    </div>
}
const Subtext2 = (props)=>{
    return <div className='subtextContainer'>
        <h1 className="featureTitle" style={{marginTop:"20px",marginBottom:"0",fontSize:"40px"}}>
            {props.text}
        </h1>
    </div>
}
const Image = (props)=>{
    const imageStyling = {
    borderRadius:"20px",
    width:"400px",
    
};
    
    return <div className="imageContainer gridItem">
        <img
            style={imageStyling}
            alt='image'
            src={props.path}
            className='image'
        ></img>
    </div>
    
}
export default IntroPage
