import Auth from './components/auth/auth'
import { BrowserRouter as Router, Route,Routes } from 'react-router-dom';
import IntroPage from './pages/introPage/introPage'
import HomePage from './pages/homePage/homePage'
import WorkoutBuilder from './pages/workoutBuilder/workoutBuilder';
import WorkoutPlayer from './pages/workoutPlayer/workoutPlayer';
import WorkoutEditor from './pages/workoutEditor/workoutEditor';
import {useAuthState} from 'react-firebase-hooks/auth'
import {auth} from './config/firebase'
import PrivacyPolicy from './pages/privacyPolicy';

function App() {

  const [user,loading,undefined] = useAuthState(auth);

  
  return (
    <Router>
      <Routes>
      <Route path="/" element={loading?null:
          (user?<HomePage></HomePage>:<IntroPage></IntroPage>)
        } />
        
        <Route path="/signin" element={<Auth newUser={false} />}/>
        <Route path="/signup" element={<Auth newUser={true} />}/>
        
        <Route path="/newworkout" element={loading?null:
          (user?<WorkoutBuilder user={user}></WorkoutBuilder>:<IntroPage></IntroPage>)
        } />
        <Route path="/startworkout/:id" element={loading?null:
          (user?<WorkoutPlayer user={user}></WorkoutPlayer>:<IntroPage></IntroPage>)
        } />
        <Route path="/:id/edit" element={loading?null:
          (user?<WorkoutEditor user={user}></WorkoutEditor>:<IntroPage></IntroPage>)
        } />
        <Route path="/privacy-policy" element={<PrivacyPolicy></PrivacyPolicy>}></Route>
        
      </Routes>
    </Router>
  );
}

export default App;
