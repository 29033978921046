import BreakCheckBox from "./breakCheckbox"
import Exercise from "./exercise"
import './workoutBuilderStyling.css'
import {db} from './../../config/firebase'
import {collection,addDoc} from 'firebase/firestore'
import {useEffect, useState,useRef} from "react"
import { useNavigate,Link } from 'react-router-dom';
import AminoLogo from "../../components/header/logo"


let itemId = 0

const WorkoutBuilder = (props)=>{    
    const workoutsCollectionRef = collection(db,"Users",props.user.uid,"Workouts")
    const [breakAfter, setBreakAfter] = useState({minutes:0,seconds:0})
    const [workoutTitle, setWorkoutTitle] = useState("")
    const [workoutDescription, setWorkoutDescription] = useState("")
    const [exerciseList, setExerciseList] = useState([])
    const navigate = useNavigate();

    const seconds = useRef(5)
    const minutes = useRef(0)

    useEffect(()=>{
        setExerciseList([{id:itemId,title:"meaning",minutes:0,seconds:5,breakAfter:{minutes:breakAfter.minutes,seconds:breakAfter.seconds}}])
    },[])
    

    const createNewExercise=()=>{
        itemId+=1
        const newExercise = {id:itemId,title:"",minutes:minutes.current,seconds:seconds.current,breakAfter:{minutes:breakAfter.minutes,seconds:breakAfter.seconds}}
        setExerciseList([...exerciseList, newExercise]);
        window.scrollTo(0,document.body.scrollHeight);
    }
    
    const updateTitle = (newTitle, exerciseId) =>{
        setExerciseList(exerciseList.map((e)=>(
            e.id===exerciseId ? 
            {id:e.id,
            title:newTitle,
            minutes:e.minutes,
            seconds:e.seconds,
            breakAfter:e.breakAfter}
            :e
        )))
    }
    const updateMinutes = (newMinute, exerciseId) =>{
        setExerciseList(exerciseList.map((e)=>(
            e.id===exerciseId ? 
            {id:e.id,
            title:e.title,
            minutes:newMinute>=0&&newMinute<=10?newMinute:0,
            //I have the condition above in order to idiot proof the website
            //I don't any one to inspect the page and change the value of the minutes or seconds time.
            seconds:e.seconds,
            breakAfter:e.breakAfter}
            :e
        )))
        minutes.current = newMinute
    }
    const updateSeconds = (newSeconds, exerciseId) =>{
        setExerciseList(exerciseList.map((e)=>(
            e.id===exerciseId ? 
            {id:e.id,
            title:e.title,
            minutes:e.minutes,
            seconds:newSeconds>=5&&newSeconds<=55?newSeconds:5,
            breakAfter:e.breakAfter}
            :e
        )))
        seconds.current = newSeconds
    }
    
    const updateBreak = (breakMinutes,breakSeconds) =>{
        setBreakAfter({minutes:breakMinutes,seconds:breakSeconds})
        setExerciseList(exerciseList.map((e)=>(
            {id:e.id,
            title:e.title,
            minutes:e.minutes,
            seconds:e.seconds,
            breakAfter:{minutes:breakMinutes,seconds:breakSeconds}}
        )))
    }
    const deleteExercise=(exerciseId)=>{
        if(exerciseList.length<=1)return
        setExerciseList(exerciseList.filter(exercise=>exercise.id!==exerciseId))
    }
    const [currentDrag,setCurrentDrag]=useState(null)
    const setNewDrag = (index) =>{
        setCurrentDrag(index)
    }
    function handleSort(draggedOverElement) {
        const exercisesClone = [...exerciseList]
        const temp = exercisesClone[currentDrag]
        exercisesClone[currentDrag]=exercisesClone[draggedOverElement]
        exercisesClone[draggedOverElement] = temp
        setExerciseList(prevList => [...prevList]);
        setExerciseList(exercisesClone)
      }
    const finishWorkout = async () =>{
        
        try{
            const workoutObject = {
                title:workoutTitle,
                description:workoutDescription,
                exercises:exerciseList
            }
            await addDoc(workoutsCollectionRef,workoutObject)
            navigate("/")
        }
        catch(err){
            
            console.error(err)
            alert(err.message)
        }
    }
    return <div className="workoutBuilderContainer" >
        <div className="topSection">
            <Link to="/">
            <AminoLogo noText={true}></AminoLogo>
            </Link>
            <h1 style={{display:'flex',alignItems:"center"}}>New workout</h1>
        </div>
        <div className="workoutBuilderForm">
            <div className="titleAndCreate" >
                <input
                className="textarea"
                type="text"
                placeholder="Enter a title"
                style={{margin:"0",padding:"12px",fontSize:"16px"}}
                /* The padding makes it so that the input and the button beside it
                have identical height and are perfectly aligned. */
                onChange = {(e)=>{setWorkoutTitle(e.target.value)}}
                ></input>
                
                <button className="finishWorkout" onClick={finishWorkout}>
                    <h2 style={{margin:'0',
                    color:"white",
                    fontFamily: "Inter, -apple-system, BlinkMacSystemFont, avenir next,avenir, segoe ui, helvetica neue, helvetica, Cantarell, Ubuntu, roboto,noto, arial, sans-serif",
        
                    letterSpacing: "-0.025em"
                    }}>Create</h2>
                </button>
            
            </div>
            <textarea 
            className="textarea" 
            placeholder="Enter a description" 
            style={{height:"80px"}}
            onChange = {(e)=>{setWorkoutDescription(e.target.value)}}
            ></textarea>
            <BreakCheckBox title="Break between exercises" updateBreak={updateBreak}></BreakCheckBox>
            <div className="exercisesList" >
                {exerciseList.map((e,index)=>(<Exercise
                setDrag={setNewDrag}
                indexInList = {index}
                currentDrag = {currentDrag}
                handleSort = {handleSort}
                id ={e.id}
                key={e.id}
                deleteExercise={deleteExercise}
                updateMinutes={updateMinutes}
                updateSeconds={updateSeconds}
                updateTitle={updateTitle}
                minutes = {minutes.current}
                seconds = {seconds.current}
                ></Exercise>))}
                <button className="newExerciseButton" onClick={createNewExercise}>+</button>
            </div>
        </div>
    </div>
}
export default WorkoutBuilder