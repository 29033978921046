import './homePageStyling.css'
import { useRef,useState,useEffect} from 'react';
import { Link } from 'react-router-dom';

const WorkoutsGrid = (props)=>{
    const workoutsList = props.workoutsList

    const [menuIsVisible, setMenuIsVisible] = useState(false);
    const [selectedWorkout, setSelectedWorkout] = useState(null);
    const menuRef = useRef(null);
    const openRef = useRef(false)
    useEffect(() => {
      const handleOutsideClick = (event) => {
        if(openRef.current&&
          !event.target.classList.contains("menuButton")&&
          !event.target.classList.contains("con")&&
          !event.target.classList.contains("menuText")){
          setMenuIsVisible(false)
          setSelectedWorkout(null) 
        }
          
        
      };
  
      // Attach the event listener to the document
      document.addEventListener('click', handleOutsideClick);
  
      // Clean up the event listener on component unmount
      return () => {
        document.removeEventListener('click', handleOutsideClick);
      };
    }, [])
  
    useEffect(()=>{
      openRef.current=menuIsVisible
    },[menuIsVisible])

  const toggleMenu = (workoutId,event) => {
    event.preventDefault();

    if(event.target.classList.contains("menuText"))return
    setMenuIsVisible(!menuIsVisible)
    setSelectedWorkout(workoutId)      
  };
  const workoutLinkStyling = {
    textDecoration:"none",
    width:"-webkit-fill-available",
    marginRight:"5px"
  }
  const workoutTitleStyling = {
    margin:'0',
    color:"black",
    fontWeight:"bold",
    fontFamily: "Inter, -apple-system, BlinkMacSystemFont, avenir next,avenir, segoe ui, helvetica neue, helvetica, Cantarell, Ubuntu, roboto,noto, arial, sans-serif",
    letterSpacing: "-0.025em"
  }
    return <div className="workoutsGrid">
      <Link to="/newworkout"  className='newWorkout' style={{textDecoration:'none'}}>
      <h3 style={{margin:'0',color:"black",fontWeight:"bold"}}>+ Add Workout</h3>
      </Link>          
      
        {workoutsList.map((workout)=>(
            <Link to={`startworkout/${workout.id}`} className="workout" key={workout.id} style={{textDecoration:"none"}}> 
                    <h3 style={workoutTitleStyling}>{workout.workout.title}</h3>{/* </Link> */}
                    
                    <div className='menuButton' onClick={(e)=>{toggleMenu(workout.id,e)}}>
                      <ThreeDots handleClick={(e)=>{toggleMenu(workout.id,e)}}></ThreeDots>  
                      
                    {menuIsVisible && selectedWorkout === workout.id && (
                <div className='workoutMenu' ref={menuRef}>
                  <p className='menuText' onClick={()=>{
                    props.showRename(workout.id)}}>Rename</p>
                  <p className='menuText' onClick={()=>{
                    props.showDelete(workout.id)}}>Delete</p>
                  <Link to={`startworkout/${workout.id}`} style={{textDecoration:"none",color:"black"}}>
                    <p className='menuText' >Start</p>
                  </Link>
                  <Link to={`${workout.id}/edit`} style={{textDecoration:"none",color:"black"}}>
                    <p className='menuText'>Edit workout</p>
                  </Link>
                  
                </div>
                )}
                    </div>
              
              
            </Link>
        ))}
    </div>
}
const ThreeDots = (props)=>{
  return <svg className="menuButton" onClick = {props.handleClick} width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path className="menuButton" d="M13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6C12.5523 6 13 5.55228 13 5Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  <path className="menuButton" d="M13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  <path className="menuButton" d="M13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
}
export default WorkoutsGrid