import {useNavigate, Link} from 'react-router-dom';
import React, {useContext} from 'react';
import {SearchContext} from './../../pages/homePage/homePage';
import {auth} from './../../config/firebase'
import { signOut } from 'firebase/auth';
import AminoLogo from './logo';
import './headerStyling.css'
import ProfileButton from './profileButton/profileButton';

const Header = (props)=>{
  const navigate = useNavigate();
    const logout = async () =>{
        try{
            await signOut(auth)
            navigate('/')
        }catch(err){
            console.error(err)
            alert(err.message)
        }    
    }   
    const headerContainerStyling ={
        backgroundColor:"white",
    }
    const horitzontalLineStyling = {
        // boxShadow:"0 1px 2px 0 rgba(0,0,0,.1)"
    }

    return <div style={headerContainerStyling}>
        {props.introPage?
            <div className='headerContent'>
                <Link to="/" style={{textDecoration:"none"}}>
                    <AminoLogo></AminoLogo>
                </Link>
                <Link to="/signup" style={{textDecoration:"none"}}>
                    <button className='logout' >
                        <h2 style={{margin:'0',color:"white"}}>Get started</h2>
                    </button>
                </Link>
                
            </div>
        :
            <div className='headerContent'>
                <Link to="/" style={{textDecoration:"none"}}>
                    <AminoLogo></AminoLogo>
                </Link>
                <SearchInput></SearchInput>

                <ProfileButton 
                logoutFunction={logout}
                auth = {auth}
                imgURL={auth.currentUser.photoURL===null?require('./defaultProfile (1).png'):
                auth.currentUser.photoURL
                }></ProfileButton>
            </div>
        }
        <hr style={{margin:"0",border:"1px solid grey"}}></hr>
    </div>
}
const SearchInput=()=>{

    const searchContext = useContext(SearchContext)
    const searchInputStyling = {
        border:"none",
        padding:"5px",
        width:"100%"
    }
    return <div  className='searchContainer'>
        <SearchIcon></SearchIcon>
        <input style={searchInputStyling} placeholder='Search' 
        onChange={(e)=>{searchContext(e.target.value)}}
        ></input>
    </div>
}
const SearchIcon =()=>{
    return <svg className="searchSVG" width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.7955 15.8111L21 21M18 10.5C18 14.6421 14.6421 18 10.5 18C6.35786 18 3 14.6421 3 10.5C3 6.35786 6.35786 3 10.5 3C14.6421 3 18 6.35786 18 10.5Z" stroke="grey" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
}
export const FitMakerLogo = (props)=>{
    return <div style={{display:"flex",alignItems:"center", justifyContent:"center"}}>
        <svg style ={{margin:"10px"}} width="70" height="70" viewBox="0 0 27 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.8837 1C6.88463 1 16.9629 17 18.8828 17C20.8027 17 29.681 1 24.2819 1H12.8837Z" fill="#67B9DC" stroke="#67B9DC"/>
<path d="M6.87058 17.2272C5.53593 17.0053 3.57748 7.38095 4.28726 6.40396C4.99703 5.42697 14.9104 5.15675 15.5333 6.35423C16.1561 7.55171 8.20523 17.4491 6.87058 17.2272Z" fill="#EAE21F" stroke="#EAE21F" strokeWidth="2"/>
<circle cx="14" cy="13" r="4.5" fill="#F65757" stroke="#F65757"/>
</svg>


        {props.noText?null:<h1  className='logoText'>portable.fit</h1>}
    </div> 
    
}
export default Header