const svgStyling = {display:"flex",justifyContent:"center",alignItems:'center',margin:"10px"}

const nextStyling = {
    marginRight:"10px",
    display:"flex",
    justifyContent:"center",
    alignItems:'center',
    minWidth: "20px",
    minHeight: "20px"
}
export const PauseButton = ()=>{
    return <svg style={svgStyling} width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="4.92333" height="21" rx="1" fill="#ffffff"/>
    <rect x="11.0767" width="4.92333" height="21" rx="1" fill="#ffffff"/>
    </svg>
}
export const PlayButton = ()=>{
    return <svg style={svgStyling} width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.5 9.13397C17.1667 9.51887 17.1667 10.4811 16.5 10.866L1.5 19.5263C0.833333 19.9112 0 19.4301 0 18.6603V1.33975C0 0.569945 0.833333 0.0888208 1.5 0.473721L16.5 9.13397Z" fill="#ffffff"/>
    </svg>
}
export const Next = ()=>{
    return <svg style={nextStyling} width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.5 11.134C23.1667 11.5189 23.1667 12.4811 22.5 12.866L7.5 21.5263C6.83333 21.9112 6 21.4301 6 20.6603V3.33975C6 2.56995 6.83333 2.08882 7.5 2.47372L22.5 11.134Z" fill="#ffffff"/>
<rect x="24" y="2" width="2" height="20" rx="1" fill="#ffffff"/>
</svg>
}
export const Previous = ()=>{
    return <svg style={{marginLeft:"10px",display:"flex",justifyContent:"center",alignItems:'center',transform:"rotate(180deg)"}} width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.5 11.134C23.1667 11.5189 23.1667 12.4811 22.5 12.866L7.5 21.5263C6.83333 21.9112 6 21.4301 6 20.6603V3.33975C6 2.56995 6.83333 2.08882 7.5 2.47372L22.5 11.134Z" fill="#ffffff"/>
<rect x="24" y="2" width="2" height="20" rx="1" fill="#ffffff"/>
</svg>
}

export const Home=()=>{
    return <svg version="1.0" id="Layer_1" xmlns="http://www.w3.org/2000/svg"xlink="http://www.w3.org/1999/xlink" 
	 width="24px" height="24px" viewBox="0 0 64 64" enableBackground="new 0 0 64 64"space="preserve">
<path fill="#231F20" d="M62.79,29.172l-28-28C34.009,0.391,32.985,0,31.962,0s-2.047,0.391-2.828,1.172l-28,28
	c-1.562,1.566-1.484,4.016,0.078,5.578c1.566,1.57,3.855,1.801,5.422,0.234L8,33.617V60c0,2.211,1.789,4,4,4h16V48h8v16h16
	c2.211,0,4-1.789,4-4V33.695l1.195,1.195c1.562,1.562,3.949,1.422,5.516-0.141C64.274,33.188,64.356,30.734,62.79,29.172z"/>
                </svg> 
}

export const Edit=()=>{
    return <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    
    <title/>
    
    <g id="Complete">
    
    <g id="edit">
    
    <g>
    
    <path d="M20,16v4a2,2,0,0,1-2,2H4a2,2,0,0,1-2-2V6A2,2,0,0,1,4,4H8" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    
    <polygon fill="none" points="12.5 15.8 22 6.2 17.8 2 8.3 11.5 8 16 12.5 15.8" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    
    </g>
    
    </g>
    
    </g>
    
    </svg>
}