import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {doc, getDoc} from 'firebase/firestore'
import {auth,db} from '../../config/firebase'
import React, {  useState,useEffect,useRef,createContext } from 'react';
import './workoutPlayerStyling.css'
import { Home,Edit } from './pausePlay';
import { useWakeLock } from 'react-screen-wake-lock';
import {useDocument} from 'react-firebase-hooks/firestore'

import ExerciseInfo from './exerciseInfo/exerciseInfo';

export const ExerciseContext = createContext()

const WorkoutPlayer = () =>{
    const [workoutStatus, setWorkoutStatus]=useState("")
    const [workoutPaused, setWorkoutPaused]=useState(false)

    const [breakOver, setBreakOver] = useState(null)
    const [workoutInfo, setWorkoutInfo]=useState(null)
    const [workoutTitle, setWorkoutTitle]=useState("")
    const [workoutDescription, setWorkoutDescription]=useState("")
    
    const [currentTitle, setCurrentTitle]=useState("")
    const [currentTens, setCurrentTens]=useState(0)
    const [currentSeconds, setCurrentSeconds]=useState(0)
    const [currentMinutes, setCurrentMinutes]=useState(0)

    const [breakExists, setBreakExists] = useState(false)


    const exercise = useRef(null)

    const startButton = useRef(null)
    const exerciseInfo = useRef(null)

    const [somethingWrong,setSomethingWrong]=useState(false)
    const problemDialog = useRef(null)

    const {id} = useParams()
    const [value, loading, error] = useDocument(

    )
    
    const workoutDocRef = doc(db,"Users",auth.currentUser.uid,"Workouts",id)
    useEffect(()=>{
        const getWorkoutInfo = async()=>{
            try{
                const docSnap = await getDoc(workoutDocRef)
                const workoutData = docSnap.data()
                setWorkoutInfo(workoutData);
                setWorkoutTitle(workoutData.title)            
                setWorkoutDescription(workoutData.description)
                
            }
            catch(err){
                console.error(err)
                setSomethingWrong(true)
                // problemDialog.current.showModal()
            }

        }
        getWorkoutInfo()
    },[])
    //good stopwatch that might also be applicable to timer: https://codepen.io/cathydutton/pen/xxpOOw
    useEffect(()=>{
        if (workoutPaused||workoutStatus!="ongoing") return;

        let interval;

        const timeout = setInterval(()=>{
                if(currentSeconds===0){
                    setCurrentSeconds(59)
                    if(currentMinutes===0){
                        if(breakOver||
                            exercise.current===workoutInfo.exercises.length-1
                            ||!breakExists){
                            nextExercise()
                            return
                        }
                        else{
                            nextBreak()
                            return
                        }
                    }
                    setCurrentMinutes(currentMinutes-1)
                }
                else{
                    setCurrentSeconds(currentSeconds-1)
                    if(currentSeconds ===4 
                        ||currentSeconds ===3 
                        ||currentSeconds ===2 ){
                        playLowBeep()
                    }
                    else if(currentSeconds ==1){
                        playHighBeep()
                    }
                }
            
             

        },1000)
        return () => clearInterval(timeout)
    },[workoutPaused,exercise.current,breakOver,currentSeconds])
    
    const lowBeepAudio = new Audio(require('./sounds/low.wav'))
    const highBeepAudio = new Audio(require('./sounds/high.wav'))
    const playHighBeep = ()=>{
        highBeepAudio.play()
    }
    const playLowBeep = ()=>{
        lowBeepAudio.play()
    }

    const nextBreak=()=>{
        if(workoutStatus==="ongoing"){
            setBreakOver(true)
            const onGoingExercise = workoutInfo.exercises[exercise.current]

            setCurrentSeconds(onGoingExercise.breakAfter.seconds)
            setCurrentMinutes(onGoingExercise.breakAfter.minutes)
            setCurrentTitle("Break")
    }}
    const nextExercise = (destination = exercise.current+1)=>{
        if(workoutStatus==="ongoing"&&destination<=workoutInfo.exercises.length-1){
            exercise.current=destination
            const onGoingExercise = workoutInfo.exercises[exercise.current]
            setBreakOver(false)

            try{
                setCurrentSeconds(onGoingExercise.seconds)
                setCurrentMinutes(onGoingExercise.minutes)
                setCurrentTitle(onGoingExercise.title)
            }
            catch(err){
                setWorkoutStatus("finished")
            }
    }
        else{
            setWorkoutStatus("finished")
        }
        
        
    }
    const startWorkout = ()=>{

        exercise.current = 0
        const onGoingExercise = workoutInfo.exercises[exercise.current]
        setBreakExists(onGoingExercise.breakAfter.minutes!==0 || onGoingExercise.breakAfter.seconds!==0)
        
        
        setWorkoutStatus("ongoing")
        setWorkoutPaused(false)

        setCurrentSeconds(onGoingExercise.seconds)
        setCurrentMinutes(onGoingExercise.minutes)
        setCurrentTitle(onGoingExercise.title)
    }
    const finishWorkout=() =>{
        setWorkoutStatus("")
    }
    
    const backToStart = ()=>{
        setWorkoutStatus("")
    }


    const exerciseContextValue = {
        currentTitle:currentTitle,
        workoutInfo:workoutInfo,
        exerciseInfo:exerciseInfo,
        currentMinutes:currentMinutes,
        currentSeconds:currentSeconds,
        workoutPaused:workoutPaused,
        setWorkoutPaused:setWorkoutPaused,
        nextExercise:nextExercise,
        exercise:exercise,
        breakOver:breakOver,
        setWorkoutStatus:setWorkoutStatus,
        startWorkout:startWorkout
        /* creating a dictionary with all the values that the child 
        component will need to inherit. */
    }
    
    return <ExerciseContext.Provider value={exerciseContextValue}>
        <div className='workoutPlayerContainer'>
            {/* TODO: turn each of the 'potential' children divs of
            workoutPlayerContainer into separate components.
            This will make it easier to interpret the code. */}
            
            <div style={{display:"flex",justifyContent:"center",alignItems:'center',flexDirection:'column'}}>
                <div style={{display:"flex",justifyContent:"center",alignItems:'center'}}>
                    <Link to="/">
                        <Home ></Home>
                    </Link>
                    <h1 className='workoutTitle'>{workoutTitle}</h1>
                    <Link to={`/${id}/edit`}>
                        <Edit></Edit>
                    </Link>
                </div>
                <h2 className='workoutDescription'>{workoutDescription}</h2>
            </div>
            <div className='exerciseInformationContainer'>
                {workoutStatus===""?//show startButton if workoutStatus = ""
                <button  ref={startButton} onClick={/* startWorkout */()=>{
                    setWorkoutStatus("ongoing")
                }} className='startWorkoutButton'>
                    <h1 style={{fontSize:"10vw",margin:"0"}}>Start</h1>
                </button>:(workoutStatus==="ongoing"?
                //show exerciseInfo if workoutStatus = "ongoing"
                <div>
                    <ExerciseInfo></ExerciseInfo>
                    <button onClick={backToStart}>stop</button>
                </div>
                :
        
                //show div below if workoutStatus = "none"
                <div className='goodJobContainer exerciseInfo'>
                    <h1>Great job!</h1>
                    <button onClick={somethingWrong?null:()=>{setWorkoutStatus("ongoing")}} className='restartButton'>
                        <h2 style={{margin:"0"}}>Restart</h2>
                    </button>
                </div>)}

            </div>

        <dialog ref={problemDialog}>
            <h3>Something went wrong…</h3>
            <Link to="/"  style={{textDecoration:'none'}}>Back to home screen</Link>
        </dialog>

        <button onClick={backToStart}>yoyoyo</button>
        </div>
    </ExerciseContext.Provider>
}

const WorkoutPending = (props) =>{
    <div>
        <button  /* ref={startButton} */ onClick={props.startWorkout} className='startWorkoutButton'> 
                <h1 style={{fontSize:"10em",margin:"0"}}>Start</h1>
            </button>
    </div>
}

export default WorkoutPlayer