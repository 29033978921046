import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from 'firebase/firestore'
import {getAnalytics} from 'firebase/analytics'

const firebaseConfig = {
  apiKey: "AIzaSyCwkeIZI2R6mnDs8eSBrwI-njAEPjQzAU0",
  authDomain: "fitmaker-c6a52.firebaseapp.com",
  databaseURL: "https://fitmaker-c6a52-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "fitmaker-c6a52",
  storageBucket: "fitmaker-c6a52.appspot.com",
  messagingSenderId: "989148705815",
  appId: "1:989148705815:web:d18e2b6ead66e0cf479852",
  measurementId: "G-D26Z9YMF7T"
};
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
export const db = getFirestore(app)
export const googleProvider = new GoogleAuthProvider()
export const analytics = getAnalytics(app);

