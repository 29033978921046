import './../workoutBuilder/workoutBuilderStyling.css'
import {useState,useRef,useEffect} from "react"

const EditorExercise = (props) =>{
    const inputRef = useRef(null)
    const secondsInputRef = useRef(null)
    const minutesInputRef = useRef(null)  
    
    const [mouseOverInput,setMouseOverInput]=useState(false)

    useEffect(()=>{
        inputRef.current.focus()
        inputRef.current.value = props.exercise.title
        minutesInputRef.current.value = props.exercise.minutes
        secondsInputRef.current.value = props.exercise.seconds
        /*
        If I want an input to have a certain value initially, this has to 
        be specified in a useEffect hook. Otherwise, for example when I change
        the title of one exercise, then all the other exercises will also be 
        set to that title.
        */
    },[])
    return <div className="exerciseContainer" 
    draggable={mouseOverInput} /* no dragging if cursor hovering over input. This way,
    the exercise won't get dragged if user drags mouse to select the written input. */
    onDragStart={()=>{
        props.setDrag(props.indexInList)
        
    }}
    onDragEnter={()=>{
        props.handleSort(props.indexInList)
        props.setDrag(props.indexInList)
    }}
    onDragOver={(e)=>{
        e.preventDefault()
        /* For some reason, by default when a draggable div is released, it 
        makes a distracting little animation. Calling preventDefault when the
        dragging is released prevents this. */
    }}
    
    >               
        <div className="exerciseInfoContainer" >
            <input style={{margin:"0"}}type="text" placeholder="Exercise" className="exerciseInput"
            onChange={(e)=>{props.updateTitle(e.target.value,props.id)}}
            onMouseEnter={()=>setMouseOverInput(false)}
            onMouseLeave={()=>setMouseOverInput(true)}
            ref = {inputRef}
            ></input>
            <div style={{marginTop:"10px"}}>
                <label style={{marginRight:"20px"}}>
                    Minutes:
                    <select ref={minutesInputRef} name="minutes" onChange={(e)=>{
                        const value = e.target.value
                        props.updateMinutes(parseInt(value),props.id)
                        }}>
                    <option value= {0}>0</option>
                    <option value= {1}>1</option>
                    <option value= {2}>2</option>
                    <option value= {3}>3</option>
                    <option value= {4}>4</option>
                    <option value= {5}>5</option>
                    <option value= {6}>6</option>
                    <option value= {7}>7</option>
                    <option value= {8}>8</option>
                    <option value= {9}>9</option>
                    <option value= {10}>10</option>
                    </select>
                </label>
                <label style={{marginLeft:"20px"}}>
                    Seconds:
                    <select name="seconds" ref={secondsInputRef} onChange={(e)=>{
                        const value = e.target.value
                        props.updateSeconds(parseInt(value),props.id)}}>
                    <option value= {5}>5</option>
                    <option value= {10}>10</option>
                    <option value= {15}>15</option>
                    <option value= {20}>20</option>
                    <option value= {25}>25</option>
                    <option value= {30}>30</option>
                    <option value= {35}>35</option>
                    <option value= {40}>40</option>
                    <option value= {45}>45</option>
                    <option value= {50}>50</option>
                    <option value= {55}>55</option>
                    </select>
                </label>
            </div>
        </div>
        <button className='buttonInExercise'
            onClick={()=>{props.deleteExercise(props.id)}}
        >
            <DeleteIcon></DeleteIcon>
        </button>
    </div>
}
const DeleteIcon = () =>{
    return <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 12V17" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14 12V17" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4 7H20" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6 10V18C6 19.6569 7.34315 21 9 21H15C16.6569 21 18 19.6569 18 18V10" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
}

export default EditorExercise
