import React, {useContext,useRef,useEffect,useState } from 'react';
import { ExerciseContext } from '../workoutPlayer';
import { PlayButton,PauseButton,Next,Previous } from '../pausePlay';
import './exerciseInfoStyling.css'
const ExerciseInfo = ()=>{
    const exerciseContext = useContext(ExerciseContext)

    const exerciseInfo=exerciseContext.exerciseInfo
    const currentTitle=exerciseContext.currentTitle
    const workoutInfo=exerciseContext.workoutInfo
    const currentMinutes=exerciseContext.currentMinutes
    const currentSeconds=exerciseContext.currentSeconds
    const workoutPaused=exerciseContext.workoutPaused
    const setWorkoutPaused=exerciseContext.setWorkoutPaused
    const nextExercise=exerciseContext.nextExercise
    const exercise=exerciseContext.exercise
    const breakOver = exerciseContext.breakOver
    const startWorkout = exerciseContext.startWorkout
    const setWorkoutStatus = exerciseContext.setWorkoutStatus

    const listOfExercises = workoutInfo.exercises

    const nextDestination = useRef(null)
    const previousDestination = useRef(null)
    

    const previousExerciseText = ()=>{
        if(breakOver===true){
            /* Sorry, but for some reason the above condition
            is true when there is a break happening…weird.Well,
            if there is a break happening you should one hand
            the exercise that just happened and on the left you
            show the next exercise. */
            previousDestination.current = exercise.current
            return listOfExercises[exercise.current].title
        }
        if(listOfExercises[exercise.current-1]){
            previousDestination.current = exercise.current-1
            return listOfExercises[exercise.current-1].title
        }
        else{
            previousDestination.current = null
            return null
        }           
    }
    const nextExerciseText = ()=>{
        if(listOfExercises[exercise.current+1]){
            nextDestination.current = exercise.current+1
            return listOfExercises[exercise.current+1].title
        }
        else{
            nextDestination.current = null
            return null
        }           
    }
    const [workoutStarted, setWorkoutStarted] = useState(false)
    const [currentCountdown, setCurrentCountdown] = useState(3)

    useEffect(()=>{
        if(workoutStarted)return
        const timeout = setTimeout(()=>{
            setCurrentCountdown(currentCountdown-1)
            if(currentCountdown===1){
                setWorkoutStarted(true)
            }
        },1000)
        return () => {
            clearTimeout(timeout)
            startWorkout()}
    },[currentCountdown, workoutStarted])

    return <div  ref={exerciseInfo} style={{width:"100%"}}>
    {workoutStarted?<div className='exerciseInfo'>
        <h1>{currentTitle}</h1>
        <h2 style={{marginTop:'0'}}>
            <span>
                {currentMinutes<10?"0":null}
                {currentMinutes}
            </span>
            :<span>
                {currentSeconds<10?"0":null}
                {currentSeconds}
            </span>
        </h2>
            <div className='exerciseButtonsContainer'>
            <div className="previousExerciseContainer" style={{justifyContent:"right"}}>
                <button className="togglePlayButton prevButton" 
                onClick={previousDestination.current!==null?()=>{nextExercise(previousDestination.current)}:null}>

                    <h3 className='previousText'>
                    {previousExerciseText()}
                    {/* If listOfExercises[exercise.current+1] exists, AKA the list
                        is not at its very end, then it will display the title of that
                        next exercise. Otherwise the value is null. */}
                    </h3>
                    
                    <Previous></Previous>
                </button>
            </div>
            <button className='togglePlayButton' style={{margin:0}}
            onClick={()=>{setWorkoutPaused(!workoutPaused)}}>
                {workoutPaused?
                <PlayButton></PlayButton>
                :
                <PauseButton></PauseButton>
                }
            </button>
            <div className="nextExerciseContainer " style={{justifyContent:"left"}}>
                <button className="togglePlayButton nextButton" 
                onClick={()=>{nextExercise(nextDestination.current)}}>
                    <Next></Next>
                    <h3 className='nextText' >
                    {nextExerciseText()}
                    {/* If listOfExercises[exercise.current+1] exists, AKA the list
                    is not at its very end, then it will display the title of that
                    next exercise. Otherwise the value is null. */}
                    </h3>
                </button>
            </div>
        </div>
    </div>:
    <div style={{width:"100%",display:"flex",justifyContent:"center"}}>
        <h1 style={{fontSize:"15vw"}}>{currentCountdown}</h1>
    </div>}
    
</div>
}
export default ExerciseInfo