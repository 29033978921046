import './../workoutBuilder/workoutBuilderStyling.css'
import { useParams,useNavigate,Link } from 'react-router-dom';
import {doc,getDoc,setDoc} from 'firebase/firestore'
import {auth,db} from '../../config/firebase'
import  { useEffect, useState,useRef,useId} from 'react';
import EditorExercise from './editorExercise';
import EditorBreakCheckbox from './editorBreakCheckbox';
import AminoLogo from '../../components/header/logo';

let itemId = 0
/* 
The workout editor is going to be very similar to the workout builder. 
The only difference is that all the values are already set. 
*/
const WorkoutEditor = () =>{
    const [workoutInfo, setWorkoutInfo] = useState({})
    const [breakAfter, setBreakAfter] = useState({minutes:0,seconds:0})
    const [workoutTitle, setWorkoutTitle] = useState("")
    const [workoutDescription, setWorkoutDescription] = useState("")
    const [exerciseList, setExerciseList] = useState([])
    const seconds = useRef(5)
    const minutes = useRef(0)

    const exerciseIdentifier = useId()
    const navigate = useNavigate();
    const {id} = useParams()
    const workoutDocRef = doc(db,"Users",auth.currentUser.uid,"Workouts",id)
    useEffect(()=>{
        const getWorkoutInfo = async()=>{
            try{
                const docSnap = await getDoc(workoutDocRef)
                const workoutData = docSnap.data()
                setWorkoutInfo(workoutData)
                setExerciseList(workoutData.exercises)
                setWorkoutDescription(workoutData.description)
                setWorkoutTitle(workoutData.title)
                

            }
            catch(err){
                console.error(err)
                alert(err.message)
                
            }
            
        }
            getWorkoutInfo()
        },[])
    const createNewExercise=()=>{
        itemId = generateRandomKey(20)
        const newExercise = {id:itemId,title:"",minutes:minutes.current,seconds:seconds.current,breakAfter:{minutes:breakAfter.minutes,seconds:breakAfter.seconds}}
        setExerciseList([...exerciseList, newExercise]);

    }
    const generateRandomKey=(length)=> {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
      }
      
    
    const updateTitle = (newTitle, exerciseId) =>{
        setExerciseList(exerciseList.map((e)=>(
            e.id===exerciseId ? 
            {id:e.id,
            title:newTitle,
            minutes:e.minutes,
            seconds:e.seconds,
            breakAfter:e.breakAfter}
            :e
        )))
    }
    const updateMinutes = (newMinute, exerciseId) =>{
        setExerciseList(exerciseList.map((e)=>(
            e.id===exerciseId ? 
            {id:e.id,
            title:e.title,
            minutes:newMinute>=0&&newMinute<=10?newMinute:0,
            /* I have the condition above in order to idiot proof the website
            I don't any one to inspect the page and change the value of the 
            minutes or seconds time. */
            seconds:e.seconds,
            breakAfter:e.breakAfter}
            :e
        )))
        minutes.current = newMinute
    }
    const updateSeconds = (newSeconds, exerciseId) =>{
        setExerciseList(exerciseList.map((e)=>(
            e.id===exerciseId ? 
            {id:e.id,
            title:e.title,
            minutes:e.minutes,
            seconds:newSeconds>=5&&newSeconds<=55?newSeconds:5,
            breakAfter:e.breakAfter}
            :e
        )))
        seconds.current = newSeconds
    }
    
    const updateBreak = (breakMinutes,breakSeconds) =>{
        setBreakAfter({minutes:breakMinutes,seconds:breakSeconds})
        setExerciseList(exerciseList.map((e)=>(
            {id:e.id,
            title:e.title,
            minutes:e.minutes,
            seconds:e.seconds,
            breakAfter:{minutes:breakMinutes,seconds:breakSeconds}}
        )))
    }
    const deleteExercise=(exerciseId)=>{
        if(exerciseList.length<=1)return
        setExerciseList(exerciseList.filter(exercise=>exercise.id!==exerciseId))
    }
    const [currentDrag,setCurrentDrag]=useState(null)
    const setNewDrag = (index) =>{
        setCurrentDrag(index)
    }
    function handleSort(draggedOverElement) {
        const exercisesClone = [...exerciseList]
        const temp = exercisesClone[currentDrag]
        exercisesClone[currentDrag]=exercisesClone[draggedOverElement]
        exercisesClone[draggedOverElement] = temp
        setExerciseList(prevList => [...prevList]);
        setExerciseList(exercisesClone)
        }
    
    const finishWorkout = async ()=>{
        try{
            const workoutObject = {
                title:workoutTitle,
                description:workoutDescription,
                exercises:exerciseList
            }
            await setDoc(workoutDocRef,workoutObject)
            navigate("/")
        }
        catch(err){
            console.error(err)
            alert(err.message)
        }
        
    }
    /* Reason for the condition !exerciseList.length>0 is that in the line about
    the break checkbox I pass in "exerciseList[0].breakAfter" as a prop. 
    I think that while the data from firestore is being fetched, the exerciseList
    variable in the useState hook is empty, so saying "exerciseList[0].breakAfter"
    would cause an error. With the condition below, "exerciseList[0].breakAfter"
    is only mentioned once I am sure that is actually an exercise in the list */

    return !exerciseList.length>0?null:<div className="workoutBuilderContainer" >
    <div className="topSection">
        <Link to="/">
            <AminoLogo noText={true}></AminoLogo>
        </Link>
        <h1 style={{display:'flex',alignItems:'center'}}>Edit workout</h1>
    </div>
    <div className="workoutBuilderForm">
        <div className="titleAndCreate">
            <input
            className="textarea"
            type="text"
            placeholder="Enter a title"
            style={{margin:"0",padding:"12px"}}
            value={workoutTitle}
            onChange = {(e)=>{setWorkoutTitle(e.target.value)}}
            ></input>

            <button className="finishWorkout" onClick={finishWorkout}>
                <h2 style={{margin:'0',color:"white"}}>Save</h2>
            </button>
        </div>
        <textarea 
        className="textarea" 
        placeholder="Enter a description" 
        value={workoutDescription}
        style={{height:"80px"}}
        onChange = {(e)=>{setWorkoutDescription(e.target.value)}}
        ></textarea>
        <EditorBreakCheckbox breakAfter={exerciseList[0].breakAfter} title="Break between exercises" updateBreak={updateBreak}></EditorBreakCheckbox>
        {/*I pass the first exercise of the  exercises list as a prop.
    This way, the break checkbox component knows what the break duration 
    for the entire workout is. I pass in the first element because I 
    think the way I have it setup, it is impossible for a workout to 
    have less than one exercise.
    */}
        <div className="exercisesList" >
            {exerciseList.map((e,index)=>(<EditorExercise
            setDrag={setNewDrag}
            indexInList = {index}
            currentDrag = {currentDrag}
            handleSort = {handleSort}
            id ={e.id}
            key={e.id}
            deleteExercise={deleteExercise}
            updateMinutes={updateMinutes}
            updateSeconds={updateSeconds}
            updateTitle={updateTitle}
            minutes = {minutes.current}
            seconds = {seconds.current}
            exercise = {e}
            ></EditorExercise>))}
            <button type = "submit"className="newExerciseButton"  onClick={createNewExercise}>+</button>
        </div>
    </div>
</div>
}
export default WorkoutEditor