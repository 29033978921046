import {useState,useEffect} from 'react'
const EditorBreakCheckbox = (props) =>{
    const containerStyling={
        display:"flex",
        justifyContent:"center",        
        flexDirection:"column"
    }
    
    const [breakMinutes, setBreakMinutes] = useState(0)
    const [breakSeconds, setBreakSeconds] = useState(0)
    const [exerciseBreaks, setExerciseBreaks] = useState("none")
    const breakAfter = props.breakAfter
    const noBreak = breakAfter.minutes===0&&breakAfter.seconds===0
    useEffect(()=>{
        if(noBreak){
            setExerciseBreaks("none")
        }
        else{
            setExerciseBreaks("")
        }
    },[])
    return <form style={containerStyling} className="checkboxContainer" >
        <label style={{display:"flex",justifyContent:"center"}}>
            {props.title}
            <input type="checkbox" checked={noBreak?null:"checked"} onChange={()=>{
                if(exerciseBreaks==="none"){
                    setExerciseBreaks("")
                    props.updateBreak(0,5)
                }
                else{
                    setExerciseBreaks("none")
                    props.updateBreak(0,0)
                }
                }}></input>
        </label>
        <div style={{display:exerciseBreaks}}>
        <div style={{marginTop:"10px"}}>
            <label style={{marginRight:"20px"}}>
                Minutes:
                <select name="minutes" value={breakAfter.minutes} onChange={(e)=>{
                    const value = parseInt(e.target.value)
                    setBreakMinutes(value)
                    props.updateBreak(value,breakSeconds)
                    
                    }}>
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                </select>
            </label>
            <label style={{marginLeft:"20px"}}>
                Seconds:
                <select name="seconds" value={breakAfter.seconds} onChange={(e)=>{
                    const value = parseInt(e.target.value)
                    setBreakSeconds(value)
                    props.updateBreak(breakMinutes,value)
                    
                    
                    }}>
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25">25</option>
                <option value="30">30</option>
                <option value="35">35</option>
                <option value="40">40</option>
                <option value="45">45</option>
                <option value="50">50</option>
                <option value="55">55</option>
                </select>
            </label>
        </div>
        </div>
        
    </form>
}
export default EditorBreakCheckbox