import './authStyling.css'
import { Link, useNavigate } from 'react-router-dom';
import {auth, googleProvider, db} from '../../config/firebase'
import { createUserWithEmailAndPassword, signInWithPopup, signInWithEmailAndPassword } from 'firebase/auth';
import React, { useState,useRef } from 'react';
import { doc, setDoc, collection, addDoc} from 'firebase/firestore'

/* 
PROBLEM TO FIX
In the Firestore security rules, we mentioned that the user can only write to the
database if he is signed in and is the owner of the user id. However, this blocks
someone from signing in to the website because for that, you would have to add a
new user to the database.
*/

const Auth = (props) =>{
return <SignUp newUser = {props.newUser}></SignUp>

}
export default Auth

function SignUp(props){
    
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [userName, setUsername] = useState("")
    const [error, setError] = useState("")
    const navigate = useNavigate()

    const emailInputRef = useRef(null)
    const passwordInputRef = useRef(null)
    const usernameInputRef = useRef(null)
    const errorMessageRef = useRef(null)

  const addUser = async () => { 
    const documentRef = doc(db,"Users",auth.currentUser.uid) 
    // user's doc's id in db has same id an in auth.
    try{
        await setDoc(documentRef,{
            Name:auth.currentUser.displayName===null 
                ? userName
                : auth.currentUser.displayName
        })        
        const docSubcollectionRef = collection(documentRef, 'Workouts')
            await addDoc(docSubcollectionRef,{
              /* In firestore security rules, we ran into the issue
              where when I was creating this workouts collection at sign up
              by creating an empty document in the 
              "collection(documentRef, 'Workouts')" path, firestore said that 
              I had insufficient permission because I had the security rule setup
              so that the workouts collection could only be written-to if the 
              workout had a specific format, so I ended up deleting that rule.
              However, it might be cool to create a "Starter Workout" instead 
              of an empty document once the .user signs up. 
              For two reasons:
              1. We won't have to go through the hassle of filtering out that
                first document that appears in the workouts collection at the start
              2. the rule that a workout must have a specific format is VERY important
                and creating this starter workout allows us to implement that rule.*/
        })
        /* start a subcollection "workouts" and add a document to it.
        you have to add a document, otherwise the colleciton won't be created. */
        
    }
    catch(err){
        setError(err.message)
        /* I have to say err.message because 'err' by itself is actually 
        a javascript object, which isn't allowed as usestate. err.message 
        gives me the error message. */
        console.error(err)
        
    }
    
    }

    const signUpWithEmail = async () =>{
        try{
            await createUserWithEmailAndPassword(auth,email,password)            
            await addUser()
            navigate('/')
        }
        catch(err){
            setError(err.message)
            /* I have to say err.message because 'err' by itself is actually 
            a javascript object, which isn't allowed as usestate. err.message 
            gives me the error message. */
            console.error(err)

        }
        

    }
    const signUpWithGoogle = async () =>{
        try{
            await signInWithPopup(auth, googleProvider)
            if(props.newUser){
                await addUser()
            }
            navigate('/')
        }
        catch(err){
            setError(err.message)
            /* I have to say err.message because 'err' by itself is actually 
            a javascript object, which isn't allowed as usestate. err.message 
            gives me the error message. */
            console.error(err)
        }
    }
    const signInWithEmail = async () =>{
        try{
            await signInWithEmailAndPassword(auth,email,password)
            navigate('/')            
            
        }
        catch(err){
            setError(err.message)
            /* I have to say err.message because 'err' by itself is actually 
            a javascript object, which isn't allowed as usestate. err.message 
            gives me the error message. */
            console.error(err)
            
        }
    }
    const inputStyling = {
        border:"1px solid grey",
        borderRadius:"5px",
        marginTop:"20px",
        width:"-webkit-fill-available",
        padding:"10px"
    }
    const emailInputStyling = {
        backgroundColor:"white",
        border:"1px solid grey",
        borderRadius:"5px",
        width:"-webkit-fill-available",
        padding:"10px"
    }
    const errorStyling = {
        color:"red",
        textDecoration:"underline",
        marginRight:'3px'
    }
    const horizontalLineStyling={
        backgroundColor:'grey',
        height:"1px",
        width:"50%"
    }
    const orContainerStyling={
        //styling for the –––or–––– element between the two signup options.
        display:"flex",
        /* flex display makes the child elements appear horizontally instead of the 
        default vertical*/
        alignItems:"center",//centers the child elements vertically
        width:"100%"/* each ––––line must have a 50% width in comparison to the whole
        sign up form. So we have to mention the width of the parent so that the child
        component can also have a 50% width*/
    }
    return <div className="containerContainer">
        <div className="authContainer">
            <h1>{props.newUser ? "Create your account": "Welcome back"}</h1>
            <button style={inputStyling} className = "signInWithGoogle" onClick={signUpWithGoogle}>
                <svg style={{marginRight:"10px"}} className="providerIcon" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/><path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"/><path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"/><path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"/><path d="M1 1h22v22H1z" fill="none"/></svg>
                Continue with Google
                </button>
                <div style={orContainerStyling}>
                    <div style={horizontalLineStyling}></div>
                    {/* horizontal line next to the 'or'text */}

                    <p style={{padding:"5px"}}>OR</p>

                    <div style={horizontalLineStyling}></div>
                    {/* horizontal line next to the 'or'text */}
                </div>
            <form style={{width:"100%"}}className="emailPasswordContainer" onSubmit={(e)=>{
                        e.preventDefault()
                        if (props.newUser) {
                            signUpWithEmail();
                          } else {
                            signInWithEmail();
                          }
                    }
                }>
                <input ref={emailInputRef} style={emailInputStyling} className = "emailInput" placeholder="Email" 
                required onChange={(e)=>{setEmail(e.target.value)}}></input>
                
                {props.newUser ? <input style={inputStyling} required pattern=".{5,15}" 
                ref={usernameInputRef}
                title="Please enter a value between 5 and 15 characters long"
                className="usernameInput" placeholder="User name"
                onChange={(e)=>{setUsername(e.target.value)}}></input> : null}
                {/* depending on whether or not the user is signing in or signing up, there should be a user name
                input. If the user is signing in, then he already has a user name and only has to enter his email.*/}

                <input style={inputStyling} className = "passwordInput" required ref={passwordInputRef}
                placeholder="Password" type ="password" onChange={(e)=>{setPassword(e.target.value)}}></input>
                <button  type = "submit" className = "signUpButton" >
                    {props.newUser ? "Sign up" : "Sign in"}
                    </button>

                {/* "requred" and "pattern=".{5,15}"" are two input attributes that will
                ensure the following:
                    1. required: the input must be filled in order to submit form
                    2. pattern=.{5,15}: input value must be between 5 and 15 chars
                */}

            </form>
            <Link to = {props.newUser ? "/signin" : "/signup"}>
            <p onClick={()=>{
                emailInputRef.current.value=""
                if(props.newUser)usernameInputRef.current.value=""
                /* if it's an existing user, then the username input (and 
                therefore no ref to usernameInputRef) isn't there so saying
                usernameInputRef.current.value would cause an error*/
                passwordInputRef.current.value=""
                errorMessageRef.current.value=""
                /* emptying out all inputs when user switches from sign in
                to sign up. */
            }}>{
            props.newUser ? "Already have an account? Sign in" : "Don't have an account? Sign up"
            }</p>
            </Link>

            <div style={{display:error===""?"none":"flex",alignItems:'center'}} ref={errorMessageRef}>
                <p className="error" style = {errorStyling}>{error}</p>
                <CancelIcon handleClick={()=>{setError("")}}></CancelIcon>
                {/* I can't make a react component by itself have a onClick property
                like every HTML element. That is why I am passing in a property
                "handleClick" which does exactly what a onClick would do, except
                that I have to specifiy this later inside the SVG element below.  */}
            </div>
        </div>
    </div>
}
const CancelIcon =(props)=>{
    return <svg onClick={props.handleClick} className='cancelIcon' fill="red" width="15px"  height="15px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
    {/* this onClick property changes the error state in the Auth component above */}
    <title>cancel</title>
    <path d="M10.771 8.518c-1.144 0.215-2.83 2.171-2.086 2.915l4.573 4.571-4.573 4.571c-0.915 0.915 1.829 3.656 2.744 2.742l4.573-4.571 4.573 4.571c0.915 0.915 3.658-1.829 2.744-2.742l-4.573-4.571 4.573-4.571c0.915-0.915-1.829-3.656-2.744-2.742l-4.573 4.571-4.573-4.571c-0.173-0.171-0.394-0.223-0.657-0.173v0zM16 1c-8.285 0-15 6.716-15 15s6.715 15 15 15 15-6.716 15-15-6.715-15-15-15zM16 4.75c6.213 0 11.25 5.037 11.25 11.25s-5.037 11.25-11.25 11.25-11.25-5.037-11.25-11.25c0.001-6.213 5.037-11.25 11.25-11.25z"></path>
    </svg>
}