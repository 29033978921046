import {doc,updateDoc,collection,onSnapshot,deleteDoc} from 'firebase/firestore'
import {auth,db} from '../../config/firebase'
import React, { useEffect, useState,useRef,createContext } from 'react';
import WorkoutsGrid from './workoutsGrid';
import Header from './../../components/header/header'
export const SearchContext = createContext()
/* to access React's context API, I need first to create
a context. I do this by calling */

const HomePage = () =>{
  const workoutsCollectionRef = collection(db,"Users",auth.currentUser.uid,"Workouts")

  const [loading, setLoading] = useState(true);
  /* With the loading useState, we determine if the display
  should be set to nothing or to the actual workouts grid.
  Before the data from firestore comes in, it is set to nothing.
  This page has HTML elements that are both pre-written, 
  for example the header, as well as elements that have to 
  be fetched from firestore, like the workouts grid with all
  the titles. While the data is being fetched from firestore,
  the pre-written elements would just stand there by themselves
  and only after a moment would the whole page be complete with
  everything from firestore. By waiting until loading is set to
  true, we ensure that everything appears at the same time.*/
  

  const [searchText, setSearchText] = useState("")

  const workouts = useRef([])
  const [displayWorkouts, setDisplayWorkouts] = useState([])
  /* Why there are two workout states:
  one of them is made to store all the workouts nicely.
  The other is only to store the workouts that are currently
  being displayed. For the search functionality this is 
  important because depending on the search input, we might 
  need to filter some workouts out of display or show them again
  depending on if they match the search input. The workouts
  array with all workouts, however, is immutable and doesn't */

  const [newWorkoutName,setNewWorkoutName] = useState("")
  const currentWorkoutPopup = useRef(null)
  const renameInputRef = useRef(null)

  useEffect(()=>{
    const searchFilter = workouts.current.filter(workoutEl=>
      workoutEl.workout.title.toLowerCase().includes(searchText.toLowerCase()))
    setDisplayWorkouts(searchFilter)
    /* The function in this useEffect hook runs whenever the
    searchText useState changes, i.e. when the user types in
    something in the search input. I create a 'filtered' list
    by filtering the array of all workouts with the condition
    that the title contains the search input substring. Notice
    how I use toLowerCase for this: this is just because capital
    letters shouldn't make a difference in finding the desired  
    workout. Finally, I set the display workouts to the filtered
    array.*/
  },[searchText])
    
    useEffect(()=>{
      const unsubscribe = onSnapshot(workoutsCollectionRef,(snapshot)=>{
        const workoutData = snapshot.docs.map((doc)=>(
          {id:doc.id,
          workout:doc.data()}
          )) 
          const filteredData = workoutData.filter(element=>element.workout.title)
          workouts.current = filteredData
          
          setDisplayWorkouts(filteredData)
          setLoading(false)
          /* getting workout data from firestore and storing it in
          both workouts usestate arrays  */
      }
      )
      

      return () => unsubscribe();
    },[])
    
    const renameDialogRef = useRef(null)
    const deleteDialogRef = useRef(null)
    const showRename = (workoutId)=>{
      renameDialogRef.current.showModal()
      currentWorkoutPopup.current = workoutId
    }
    const showDelete = (workoutId)=>{
      deleteDialogRef.current.showModal()
      currentWorkoutPopup.current = workoutId
    }

    
    return (
      loading === true ? null : (
        <SearchContext.Provider value = {setSearchText}>
          {/* We have to wrap our component around a context provider for our 
          searchContext, this way any child in the component tree can access 
          the value of the function setSearchText that comes from this 
          SearchContext. This value will eventually be used in the searchBar
          component inside the header component so that we can use the search
          input to update the workouts grid. Pretty darn cool. */}
          <div>
            <Header></Header>
            
            <WorkoutsGrid workoutsList = {displayWorkouts} showRename={showRename} showDelete={showDelete}></WorkoutsGrid>
            <dialog ref={renameDialogRef}>
              <h2>Rename workout</h2>
              <input type='text' ref={renameInputRef} placeholder='Enter a new name' onChange={
                (e)=>{setNewWorkoutName(e.target.value)}}></input>
              <button onClick={()=>{
                renameDialogRef.current.close()
                renameInputRef.value=''
              }}>Cancel</button>
              <button onClick={async()=>{
                try{
                  const workoutDocumentRef = doc(db,"Users",auth.currentUser.uid,"Workouts",currentWorkoutPopup.current)
                  await updateDoc(workoutDocumentRef,{title:newWorkoutName})
                }
                catch(err){
                  console.error(err)
                  alert(err.message)
                }
                finally{
                  renameDialogRef.current.close()
                  renameInputRef.value=''
                }
          
              }}>OK</button>
            </dialog>
            <dialog ref={deleteDialogRef}>
              <h3>Are you sure you want to delete this workout?</h3>
              <button onClick={async()=>{
                try{
                  const workoutDocumentRef = doc(db,"Users",auth.currentUser.uid,"Workouts",currentWorkoutPopup.current)
                  await deleteDoc(workoutDocumentRef)
                }
                catch(err){
                  console.error(err)
                  alert(err.message)
                }
                finally{
                  deleteDialogRef.current.close()
                }
          
              }}>Delete</button>
              <button onClick={()=>{
                deleteDialogRef.current.close()
              }}>Close</button>
            </dialog>

          
          </div>
        </SearchContext.Provider>
      )
    );
    
}

export default HomePage
export const userInfo = auth.currentUser