import { useRef,useState,useEffect} from 'react';
import {auth} from './../../../config/firebase'

import './profileBtnStyling.css'

const ProfileButton = (props)=>{
    const [menuIsVisible, setMenuIsVisible] = useState(false);
    
    useEffect(() => {
        const handleOutsideClick = (event) => {
          if(!event.target.classList.contains("profilePhoto")&&
          !event.target.classList.contains("personalInfo")&&
          !event.target.classList.contains("nameText")&&
          !event.target.classList.contains("emailText")
          ){
            setMenuIsVisible(false)

            }
        }
        document.addEventListener('click', handleOutsideClick);

        return () => {
          document.removeEventListener('click', handleOutsideClick);
        };
      }, [])

      useEffect(()=>{
      },[menuIsVisible])

    const toggleMenu = (event) => {
        if(event.target.classList.contains("profileMenuText"))return
        setMenuIsVisible(!menuIsVisible)
      }
    return <div className="profileBtnContainer">
        <img src={props.imgURL}
        style={{borderRadius:"100%",border:"1px solid black",width:"50px"}}
        className='profilePhoto'
        alt="image"
        onClick={(e)=>{toggleMenu(e)}}></img>
        
        {menuIsVisible?<div className='profileMenu'>
            <div className='personalInfo'>
              <h3 className='nameText'>{auth.currentUser.displayName}</h3>
              <p className='emailText'>{auth.currentUser.email}</p>
              <hr style={{margin:0}}></hr>
            </div>
            <p onClick={props.logoutFunction}
             className='profileMenuText logoutButton'
            >Logout</p>

            
        </div>:null}
    </div>
    
}
export default ProfileButton

const Image = ()=>{
  return 
}