const AminoLogo = (props)=>{
    // const color = "#B6ABAB"
    const strokeColor = "#ffffff"
    const color = "#000000"
    const logoStyling = {display:"flex",justifyContent:'center',alignItems:'center'}
    return !props.noText?<div style={logoStyling}>
        {/* <svg  width="100" height="135" viewBox="0 0 257 135" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
        <svg width="100" height="135" viewBox="0 0 267 135" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M149 67H183" stroke="#656565" strokeWidth="5" strokeLinecap="round"/>
<circle cx="132.5" cy="67.5" r="18.5" fill="#CFCFCF"/>
<path d="M20 26L57 66" stroke="black" strokeWidth="5" strokeLinecap="round"/>
<path d="M237 34L202 68.4564" stroke="black" strokeWidth="5" strokeLinecap="round"/>
<path d="M20 109L57 69" stroke="black" strokeWidth="5" strokeLinecap="round"/>
<path d="M243 99L213 70" stroke="black" strokeWidth="5" strokeLinecap="round"/>
<path d="M235 107L205 78" stroke="black" strokeWidth="5" strokeLinecap="round"/>
<path d="M71 67L105 67" stroke="#656565" strokeWidth="3" strokeLinecap="round"/>
<path d="M149 67H183" stroke="#030202" strokeWidth="3" strokeLinecap="round"/>
<path d="M133 11V50" stroke="#030202" strokeWidth="3" strokeLinecap="round"/>
<ellipse cx="201.685" cy="66.5" rx="19" ry="18.5" transform="rotate(170.905 201.685 66.5)" fill="#CFCFCF"/>
<path d="M133 124V84" stroke="black" strokeWidth="3" strokeLinecap="round"/>
<circle cx="16.5" cy="111.5" r="15.5" fill="white" stroke="black" strokeWidth="5"/>
<circle cx="16.5" cy="21.5" r="15.5" fill="white" stroke="black" strokeWidth="5"/>
<path d="M88 67L122 67" stroke="black" strokeWidth="3" strokeLinecap="round"/>
<path d="M183.912 69.3451C182.388 59.8263 189.078 50.7953 198.919 49.22C208.76 47.6446 217.935 54.136 219.459 63.6548C220.983 73.1736 214.293 82.2046 204.452 83.78C194.61 85.3553 185.435 78.8639 183.912 69.3451Z" fill="white" stroke="black" strokeWidth="5"/>
<path d="M256 29C256 21.8531 249.989 16 242.5 16C235.011 16 229 21.8531 229 29C229 36.1469 235.011 42 242.5 42C249.989 42 256 36.1469 256 29Z" fill="white" stroke="black" strokeWidth="5"/>
<path d="M256 107C256 114.147 249.989 120 242.5 120C235.011 120 229 114.147 229 107C229 99.8531 235.011 94 242.5 94C249.989 94 256 99.8531 256 107Z" fill="white" stroke="black" strokeWidth="5"/>
<circle cx="132.5" cy="18.5" r="17.5" fill="white" stroke="black" strokeWidth="5"/>
<circle cx="132.5" cy="67.5" r="17.5" fill="white" stroke="black" strokeWidth="5"/>
<circle cx="18.5" cy="18.5" r="17.5" transform="matrix(1 0 0 -1 114 135)" fill="white" stroke="black" strokeWidth="5"/>
<path d="M85 66.7206C85 80.3455 73.6116 91.4412 59.5 91.4412C45.3884 91.4412 34 80.3455 34 66.7206C34 53.0957 45.3884 42 59.5 42C73.6116 42 85 53.0957 85 66.7206Z" fill="white" stroke="black" strokeWidth="5"/>
</svg>

        <h1 className="logoText" style={{margin:0,marginLeft:"10px",color:"black"}}>FitMaker</h1>
    </div>:<svg style={{marginRight:"10px"}} width="100" height="135" viewBox="0 0 267 135" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M149 67H183" stroke="#656565" strokeWidth="5" strokeLinecap="round"/>
<circle cx="132.5" cy="67.5" r="18.5" fill="#CFCFCF"/>
<path d="M20 26L57 66" stroke="black" strokeWidth="5" strokeLinecap="round"/>
<path d="M237 34L202 68.4564" stroke="black" strokeWidth="5" strokeLinecap="round"/>
<path d="M20 109L57 69" stroke="black" strokeWidth="5" strokeLinecap="round"/>
<path d="M243 99L213 70" stroke="black" strokeWidth="5" strokeLinecap="round"/>
<path d="M235 107L205 78" stroke="black" strokeWidth="5" strokeLinecap="round"/>
<path d="M71 67L105 67" stroke="#656565" strokeWidth="3" strokeLinecap="round"/>
<path d="M149 67H183" stroke="#030202" strokeWidth="3" strokeLinecap="round"/>
<path d="M133 11V50" stroke="#030202" strokeWidth="3" strokeLinecap="round"/>
<ellipse cx="201.685" cy="66.5" rx="19" ry="18.5" transform="rotate(170.905 201.685 66.5)" fill="#CFCFCF"/>
<path d="M133 124V84" stroke="black" strokeWidth="3" strokeLinecap="round"/>
<circle cx="16.5" cy="111.5" r="15.5" fill="white" stroke="black" strokeWidth="5"/>
<circle cx="16.5" cy="21.5" r="15.5" fill="white" stroke="black" strokeWidth="5"/>
<path d="M88 67L122 67" stroke="black" strokeWidth="3" strokeLinecap="round"/>
<path d="M183.912 69.3451C182.388 59.8263 189.078 50.7953 198.919 49.22C208.76 47.6446 217.935 54.136 219.459 63.6548C220.983 73.1736 214.293 82.2046 204.452 83.78C194.61 85.3553 185.435 78.8639 183.912 69.3451Z" fill="white" stroke="black" strokeWidth="5"/>
<path d="M256 29C256 21.8531 249.989 16 242.5 16C235.011 16 229 21.8531 229 29C229 36.1469 235.011 42 242.5 42C249.989 42 256 36.1469 256 29Z" fill="white" stroke="black" strokeWidth="5"/>
<path d="M256 107C256 114.147 249.989 120 242.5 120C235.011 120 229 114.147 229 107C229 99.8531 235.011 94 242.5 94C249.989 94 256 99.8531 256 107Z" fill="white" stroke="black" strokeWidth="5"/>
<circle cx="132.5" cy="18.5" r="17.5" fill="white" stroke="black" strokeWidth="5"/>
<circle cx="132.5" cy="67.5" r="17.5" fill="white" stroke="black" strokeWidth="5"/>
<circle cx="18.5" cy="18.5" r="17.5" transform="matrix(1 0 0 -1 114 135)" fill="white" stroke="black" strokeWidth="5"/>
<path d="M85 66.7206C85 80.3455 73.6116 91.4412 59.5 91.4412C45.3884 91.4412 34 80.3455 34 66.7206C34 53.0957 45.3884 42 59.5 42C73.6116 42 85 53.0957 85 66.7206Z" fill="white" stroke="black" strokeWidth="5"/>
</svg>
}
export default AminoLogo